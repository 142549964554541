<template>
  <page-main
    title="Select the adjectives and roles that best describe you"
    :aside="[
      'How do you see yourself? If you asked your friends, how would they describe you?',
      'What roles have you taken on in the past?'
    ]"
    nextText="Your Interests"
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/Career')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Adjectives"
            titleText="Adjectives:"
            :optionsList="baseAdjectives"
            :selected.sync="selectedAdjectives"
            autofocus
            tabindex="1"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Roles"
            titleText="Roles: "
            :optionsList="baseRoles"
            :selected.sync="selectedRoles"
            tabindex="2"
          ></r-multi-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
// import ValueDisplay from "../components/ValueDisplay";
import * as Display from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "Adjectives",
  data: () => ({}),
  components: {},
  methods: {
    save() {
      this.buildDisplay();
      this.saveAdjective();
      this.saveRole();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("adjective", ["saveAdjective"]),
    ...mapActions("role", ["saveRole"]),
    buildDisplay() {
      let adjValue = Display.BuildCommaString("", this.selectedAdjectives);
      let roleValue = Display.BuildCommaString("", this.selectedRoles);

      this.adjectiveDisplay = adjValue;
      this.roleDisplay = roleValue;

      return [adjValue, roleValue];
    },
  },
  mounted() {},
  computed: {
    ...mapFields("adjective", [
      "selectedAdjectives",
      "baseAdjectives",
      "adjectiveDisplay"
    ]),
    ...mapFields("role", ["selectedRoles", "baseRoles", "roleDisplay"]),
    displayValue: function() {
      return this.buildDisplay();
    }
  }
};
</script>